<template>
  <div>
    <vs-card>

      <div slot="header">
        <h3>
          {{ this.elementId?'Edit':'New' }} Element
        </h3>
      </div>
    <vs-row vs-w="12">
      <vs-col vs-type="flex" vs-w="12">
        <vs-input class="mt-5 w-full" :label-placeholder="$t('Name')"/>
      </vs-col>
      <vs-col>
        <vs-alert class="w-full mb-5" color="danger" v-show="errors.name" v-if="errors.name" active="true">
          {{$t(errors.name[0])}}
        </vs-alert>
      </vs-col>
      <vs-col class="mt-5" vs-type="flex" vs-w="12" vs-justify="flex-end">
        <vs-button @click="save">{{$t('Save')}}</vs-button>
      </vs-col>
    </vs-row>
    </vs-card>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "create",
  data(){
    return {
      errors:[],
      localData:{},
      elementId:''
    }
  },
  methods:{
    getData(){
      this.$store.dispatch("transactionCategory/SetSectionName",{'sectionName':"transaction_categories"})
      this.elementId = this.$route.params.id
      if(this.elementId){
        this.$store.dispatch('transactionCategory/GetElement',{'id':this.elementId}).then(()=>{
          this.localData = {...this.$store.getters["transactionCategory/element"]}
        })
      }
    },
    save() {
      this.$store.dispatch("transactionCategory/SetElement",{'elementData':this.localData})
      if (!this.elementId) {
        this.$store.dispatch("transactionCategory/Store").then(() => {
          this.popupActive = false
          this.$router.push({name:'transaction_categories'})
        }).catch((error) => {
          if (error.response.data.errors)
            this.errors = error.response.data.errors
        })
      } else {
        this.$store.dispatch("transactionCategory/Update", {'id': this.elementId}).then(() => {
          this.popupActive = false
          this.$router.push({name:'transaction_categories'})
        }).catch((error) => {
          if (error.response.data.errors)
            this.errors = error.response.data.errors
        })
      }
    }
  },
  created() {
    this.getData()
  }
}
</script>

<style scoped>

</style>
